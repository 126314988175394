.chat-header
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 20px
  background: $gray-100
  border-bottom: 2px solid $gray-200
  border-radius: 16px 16px 0 0

  #{$dark-theme}
    background: $gray-700
    border-color: $gray-600

  @media #{$tablet}
    justify-content: space-between
    padding: 8px

  .context-menu
    top: 58px
    left: initial
    right: 12px

    &__wrapper
      position: initial

  .profile-image
    @media #{$tablet}
      order: 1

  &__user
    display: flex
    align-items: center
    gap: 8px
    flex-grow: 1
    cursor: pointer
    user-select: none

    @media #{$tablet}
      justify-content: space-between
      text-align: center

  &__link
    display: none
    align-self: stretch
    align-items: center
    cursor: auto

    @media #{$tablet}
      display: flex

  &__title
    @media #{$tablet}
      text-align: center

  &__menu
    @media #{$tablet}
      display: none
