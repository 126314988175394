.profile-image
  position: relative
  border: 2px solid $blue-tg
  border-radius: 50%
  box-sizing: content-box
  user-select: none

  &::after
    content: ''
    position: absolute
    bottom: 0
    right: -6px
    width: 18px
    height: 18px

  &_online
    &::after
      right: 0
      width: 8px
      height: 8px
      background: $green
      border: 1px solid $white
      border-radius: 50%

  &__avatar
    border-radius: 50%
    object-fit: cover
    pointer-events: none

    span
      color: $white
