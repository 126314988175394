.input-autocomplete

  &__dropdown
    visibility: hidden
    position: fixed
    width: 368px
    max-height: 128px
    margin: 8px 0 0 -4px
    padding: 12px 0
    background: $white
    border-radius: 8px
    box-shadow: $box-shadow
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options
    z-index: 2

    #{$dark-theme}
      background: rgb(83, 84, 97, .98)

    &_active
      visibility: visible
      opacity: 1

  &__option
    display: flex
    align-items: center
    gap: 8px
    width: 100%
    padding: 4px 16px
    transition: background $tr-options
    text-align: left
    cursor: pointer

    & > .bullet + .text
      transform: translateY(-1.3px)

    &:hover
      @media (hover)
        background: #eef1f4

        #{$dark-theme}
          background: $gray-770
