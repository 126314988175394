.icon-alt
  flex-shrink: 0
  width: 20px
  height: 20px
  background-repeat: no-repeat
  background-position: center
  background-size: contain

  &_big
    width: 32px
    height: 32px

  &_check-square-offset
    background-image: url('#{$path}/img/icons-alt/check-square-offset.svg')

  &_download-simple
    background-image: url('#{$path}/img/icons-alt/download-simple.svg')

  &_files
    background-image: url('#{$path}/img/icons-alt/files.svg')

  &_manager
    background-image: url('#{$path}/img/icons-alt/manager.svg')

    &-fill
      background-image: url('#{$path}/img/icons-alt/manager-fill.svg')

    &-unbind
      background-image: url('#{$path}/img/icons-alt/manager-unbind.svg')

  &_notification
    background-image: url('#{$path}/img/icons-alt/notification.svg')

  &_paint-brush
    background-image: url('#{$path}/img/icons-alt/paint-brush.svg')

  &_pencil-simple
    background-image: url('#{$path}/img/icons-alt/pencil-simple.svg')

  &_push-pin-simple-slash
    background-image: url('#{$path}/img/icons-alt/push-pin-simple-slash.svg')

  &_push-pin-simple
    background-image: url('#{$path}/img/icons-alt/push-pin-simple.svg')

  &_question
    background-image: url('#{$path}/img/icons-alt/question.svg')

  &_reply
    background-image: url('#{$path}/img/icons-alt/reply.svg')

  &_scale-x
    transform: scaleX(-1)

  &_trash-simple
    background-image: url('#{$path}/img/icons-alt/trash-simple.svg')
