.title
  color: $text

  #{$dark-theme}
    color: $gray-200

  &_h2
    font-size: 28px
    font-weight: 500
    line-height: 32px

  &_h3
    font-size: 18px
    font-weight: 500
    line-height: 24px

  &_hide-overflow
    @include hide-overflow

  &_nowrap
    @include nowrap

  &_uppercase
    text-transform: uppercase

  &_white
    color: $white
