.select-min
  max-width: 360px
  user-select: none

  &__field
    display: flex
    align-items: center
    width: 100%
    cursor: pointer

    &:hover
      @media (hover)
        .icon
          opacity: .8
