.chips
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 16px 16px
  min-width: 100%

  &__item
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    padding: 8px
    border: 2px solid transparent
    border-radius: 16px
    box-shadow: $box-shadow
    transition: border-color $tr-options
    cursor: pointer

    #{$dark-theme}
      background: #565b62

    & > img
      height: 76px
      aspect-ratio: 4 / 3

    & > .icon
      width: 100% !important
      height: auto !important
      aspect-ratio: 1 / 1

    &:disabled
      opacity: .5
      pointer-events: none

    &:hover
      @media (hover)
        border-color: $green

    &:has(.chips__comment)
      & > .icon
        margin-top: -16px

    &_active
      border-color: $green
      pointer-events: none
