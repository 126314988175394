.select-dropdown
  padding: 12px 0
  background: $white
  border-radius: 8px
  box-shadow: $box-shadow

  #{$dark-theme}
    background: rgb(83, 84, 97, .98)

  &__option
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    width: 100%
    padding: 4px 19px 4px 17px // 19px, 17px because of field border
    text-align: left
    transition: background $tr-options
    cursor: pointer

    & > .wrapper > .wrapper > .icon
      position: relative
      top: 4px

    & > .icon
      fill: $primary-light

    &:hover
      @media (hover)
        background: #eef1f4

        #{$dark-theme}
          background: $gray-770

    &_active
      background: #eef1f4

      #{$dark-theme}
        background: $gray-770

    &_plus
      .text
        #{$any-theme}
          color: $green

  &__label
    .icon
      margin-top: -8px
