.select-field
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  max-width: 360px
  height: 48px
  padding: 4px 8px 4px 12px
  border: 1px solid $gray-250
  border-radius: 8px
  transition: border-color $tr-options
  text-align: left
  cursor: pointer

  #{$dark-theme}
    border-color: $gray-600

  &:hover
    @media (hover)
      & > .icon
        opacity: .8

  .select_error &
    border-color: $error

  &__placeholder
    #{$light-theme}
      color: $light

    #{$dark-theme}
      color: $gray-500 !important
