.instance-billing
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 8px
  padding: 16px
  background: #f3fcf7
  border-radius: 8px

  #{$dark-theme}
    background: #677f72

  .button
    #{$dark-theme}
      border-color: $gray-200
      color: $gray-200

    &:hover
      @media (hover)
        #{$dark-theme}
          border-color: $green
          color: $green

  &_unpaid
    background: #fff7f3

    #{$dark-theme}
      background: #a27a66
