.input
  position: relative
  width: 100%
  max-width: 360px

  &__placeholder
    position: absolute
    top: 12px
    left: 12px
    color: $light !important
    transition: top $tr-options, font-size $tr-options, line-height $tr-options !important
    pointer-events: none

    #{$dark-theme}
      color: $gray-500 !important

    .input__field:not(:placeholder-shown) ~ &
      top: 4px
      font-size: 12px
      line-height: 16px

  &__field
    width: 100%
    height: 48px
    padding: 12px
    border: 1px solid $gray-250
    border-radius: 8px
    color: $text-main
    font-size: 16px
    font-weight: 500
    line-height: 24px
    transition: padding $tr-options, border-color $tr-options

    #{$dark-theme}
      border-color: $gray-600
      color: $white

    &:not(:placeholder-shown)
      padding: 20px 12px 4px

    .input_error &
      border-color: $error

  &__button
    position: absolute
    top: 8px
    right: 8px
