.toggle
  width: fit-content
  border-radius: 8px

  &__button
    position: relative
    height: 32px
    padding: 0 8px
    border: 1px solid $gray-250
    transition: border-color $tr-options
    cursor: pointer
    user-select: none

    #{$dark-theme}
      border-color: $gray-600

    &:hover
      @media (hover)
        border-color: $green

    &:first-of-type
      border-radius: 8px 0 0 8px

    &:not(:first-of-type)
      margin-left: -1px

    &:last-of-type
      border-radius: 0 8px 8px 0

    &_active
      #{$any-theme}
        border-color: $green

      & + .toggle__button
        border-left-color: $green
