.page
  height: 100%
  background-color: $gray-100
  border-radius: 16px
  box-shadow: $box-shadow

  #{$dark-theme}
    background-color: $gray-700

  @media #{$tablet}
    border-radius: 16px 16px 0 0

  &__wrapper
    display: flex
    flex-direction: column
    height: 100%

  &__body
    display: flex
    flex-direction: column
    flex-grow: 1
    padding-bottom: 20px

  &__main
    display: flex
    flex-direction: column
    flex-grow: 1
    padding: 20px

    .accordion &
      padding: 0 0 20px

  &__container
    flex-grow: 1
    width: 696px
    max-width: 100%
    margin: 0 auto

  &__footer
    border-top: 2px solid #efefef
    padding: 20px

    .accordion &
      padding: 20px 0 0

    #{$dark-theme}
      border-color: $gray-600
