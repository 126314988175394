.button-color
  width: 32px
  height: 32px
  border: 1px solid $gray-250
  border-radius: 50%
  transition: opacity $tr-options
  cursor: pointer

  #{$dark-theme}
    border-color: $gray-600

  &:hover
    @media (hover)
      opacity: .8
