.prop-list
  padding: 16px
  background: $gray-150
  border-radius: 16px

  #{$dark-theme}
    background: $gray-650

  &__item
    padding: 8px
    border-bottom: 1px solid $gray-300

    .button
      margin-bottom: -4px

    #{$dark-theme}
      border-color: #9fa9c5

    &:first-of-type
      padding-top: 0
