.audio-player
  display: flex
  align-items: center
  gap: 8px
  max-width: 100%

  &_alt
    gap: 2px
    padding: 0 12px 0 3px
    background: $green
    border-radius: 16px

  &__button
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 40px
    height: 40px
    background: $blue-tg
    border-radius: 50%
    transition: opacity $tr-options
    cursor: pointer

    .icon
      width: 20px
      height: 20px

    &:disabled
      pointer-events: none
      opacity: .5

    &:hover
      @media (hover)
        opacity: .8

    .audio-player_alt &
      width: 28px
      height: 28px
      background: none

  &__wrapper
    display: flex
    flex-direction: column
    overflow: hidden

    .audio-player_alt &
      flex-direction: row

    &_gap
      gap: 4px

  &__waveform
    display: flex
    gap: 1px
    max-width: 100%
    height: 12px

    .audio-player_alt &
      @media #{$laptop}
        display: none

    &-item
      position: relative
      display: flex
      align-items: flex-end
      width: 2px
      height: 100%
      cursor: pointer

      &::after
        content: ''
        position: absolute
        left: 2px
        display: block
        width: 1px
        height: 100%

    &-scale
      width: 100%
      min-height: 1px
      background: #d9D9d9
      transition: background $tr-options, opacity $tr-options

      .audio-player_alt &
        background: $white
        opacity: .8

      &_active
        background: $blue-tg

        .audio-player_alt &
          background: $white
          opacity: 1

  // &__seek-bar
  //   width: 191px
  //   height: 1px
  //   background: #cee6f1

  //   &-track
  //     height: 100%
  //     background: $blue-tg

  &__text
    @include nowrap

    .audio-player_alt &
      width: 40px
      text-align: end
      color: $white !important
