.chat
  background-color: $gray-100
  background-repeat: no-repeat
  background-position: center
  background-size: 100% calc(100% - 30px)
  box-shadow: $box-shadow
  border-radius: 16px
  z-index: 1

  #{$dark-theme}
    background-color: $gray-700

  @media #{$tablet}
    grid-column-start: 1
    grid-column-end: 2
    grid-row-start: 2
    grid-row-end: 3
    border-radius: 16px 16px 0 0

  &_placeholder
    padding: 20px

    @media #{$tablet}
      display: none

  &__wrapper
    position: relative
    display: flex
    flex-direction: column
    height: 100%

  &__convo
    flex-grow: 1
    padding: 46px 0

    &-wrapper
      display: flex
      flex-direction: column
      justify-content: flex-end
      flex-grow: 1
      min-height: 100%
