.input-multi

  .input-alt
    &:not(:last-of-type)
      border-bottom: 1px solid $gray-300

      #{$dark-theme}
        border-color: $gray-600

      .input-alt__field
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0

    &:not(:first-of-type)
      .input-alt__field
        border-top-left-radius: 0
        border-top-right-radius: 0
