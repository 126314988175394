.message
  display: flex
  align-items: flex-end
  gap: 8px
  padding: 6px 20px
  transition: background $tr-options // quote

  @media #{$tablet}
    padding-left: 8px
    padding-right: 8px

  &_active
    background: rgb(245, 245, 245, .8)

    #{$dark-theme}
      background: rgb(82, 82, 82, .8)

  &_outgoing
    @media #{$tablet}
      justify-content: flex-end

    .message

      &__wrapper
        @media #{$tablet}
          align-items: flex-end

      &__bubble
        background: #eef1f4

        #{$dark-theme}
          background: $gray-650

        @media #{$tablet}
          border-radius: 16px 16px 0 16px

    .profile-image
      @media #{$tablet}
        order: 1

  &_thread
    & + .message
      .message__sender
        display: none

  &__wrapper
    display: flex
    flex-direction: column
    width: 100%
    min-width: 0
    max-width: 380px

    @media #{$laptop}
      max-width: calc(100% - 32px)

  &__sender
    color: $gray-500 !important

    #{$dark-theme}
      color: $gray-350 !important

  &__bubble
    display: flex
    align-items: flex-end
    gap: 8px
    width: fit-content
    max-width: 100%
    padding: 16px
    background: #e8f2fe
    border-radius: 16px 16px 16px 0

    #{$dark-theme}
      background: #45566e

    .ack
      position: relative
      margin: -8px -12px -12px 0

    .message_thread &
      border-radius: 16px

    &_edited
      padding-top: 12px
      padding-bottom: 12px

      .ack
        margin-bottom: -8px

  &__download
    max-width: 100%
    background: none
    transition: opacity $tr-options
    text-align: left
    cursor: pointer
    user-select: none

    &:disabled
      opacity: .8
      cursor: default

    &:hover
      @media (hover)
        opacity: .8

  &__text
    white-space: pre-wrap
    word-break: break-word

  &__link
    display: inline-block
    color: $blue-tg

    &:hover
      @media (hover)
        text-decoration: underline

  &__reactions
    display: flex
    flex-wrap: wrap
    gap: 4px
    margin-top: 4px

    &-item
      display: flex
      gap: 8px
      padding: 0 8px 0 4px
      border: 1px solid $gray-200
      border-radius: 99px
      color: $gray-850
      transition: border-color $tr-options
      cursor: pointer

      #{$dark-theme}
        color: $gray-200

      &:disabled
        pointer-events: none

      &:hover
        @media (hover)
          border-color: $green

      &_active
        border-color: $green

        &:hover
          @media (hover)
            border-color: $pressed

  &__time
    color: #616c76 !important

    #{$dark-theme}
      color: $gray-400 !important
