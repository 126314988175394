.select
  position: relative
  width: 100%
  max-width: 360px
  transition: opacity $tr-options
  user-select: none

  &_active
    & > .select__dropdown
      visibility: visible
      opacity: 1
      pointer-events: auto

  &_alt
    width: fit-content

    .select
      &__field
        min-width: 32px
        height: 32px
        padding: 0
        border: none

      &__dropdown
        border-radius: 12px

  &_disabled
    opacity: .6
    pointer-events: none

  &__field
    display: flex
    justify-content: space-between
    align-items: center
    height: 48px
    padding: 4px 8px 4px 12px
    border: 1px solid $gray-250
    border-radius: 8px
    transition: border-color $tr-options
    cursor: pointer

    #{$dark-theme}
      border-color: $gray-600

    &:hover
      @media (hover)
        > .icon
          opacity: .8

    &_error
      #{$any-theme}
        border-color: $error

    &_icon
      justify-content: center

      &:hover
        @media (hover)
          & > .icon
            opacity: 1

            &:not(.icon_color-blue)
              &:not(.icon_stroke)
                fill: $primary-light

            &_stroke
              stroke: $primary-light

            &_color-green
              &.icon_stroke
                stroke: $pressed

  &__placeholder
    &:not(.select_alt &)
      color: $light

      #{$dark-theme}
        color: $gray-500 !important

  &__dropdown
    position: fixed
    top: 0
    left: 0
    width: 100svw
    height: 100svh
    visibility: hidden
    opacity: 0
    z-index: 3
    transition: visibility $tr-options, opacity $tr-options
    pointer-events: none

    &-wrapper
      position: fixed
      width: max-content
      max-width: calc(100svw - 16px)
      max-height: calc(100svh - 16px)
      padding: 14px 0
      background: $white
      border-radius: 8px
      box-shadow: $box-shadow

      #{$dark-theme}
        background: rgb(83, 84, 97, .98)

  &__optgroup
    padding: 0 8px

  &__option
    display: flex
    justify-content: space-between
    align-items: center
    gap: 4px
    padding: 4px 16px
    transition: background $tr-options

    & > .icon
      position: relative
      left: 8px
      fill: #57d163

    .select_multiple &
      cursor: pointer

    &:not(&_active)
      cursor: pointer

      & > .icon
        visibility: hidden
        opacity: 0
        transition: visibility $tr-options, opacity $tr-options

      &:hover
        @media (hover)
          background: #eef1f4

          #{$dark-theme}
            background: $gray-770

    &_active
      background: #eef1f4

      #{$dark-theme}
        background: $gray-770

      & > .icon
        visibility: visible
        opacity: 1

    &_plus
      .text
        #{$any-theme}
          color: $green

  &__group
    display: flex
    align-items: center
    gap: 8px

    & > .bullet + div .wrapper .text
      transform: translateY(-1.3px)

    & > .icon
      margin-top: -4px

  &__comment
    display: flex
    gap: 6px
