.chat-footer
  background: $gray-100
  border-top: 2px solid $gray-200
  border-radius: 0 0 16px 16px
  z-index: 1

  #{$dark-theme}
    background: $gray-700
    border-color: $gray-600

  .context-menu
    top: initial
    bottom: 56px
    left: 12px

    &__wrapper
      position: initial

  &_alt
    flex-shrink: 0
    height: 32px

  &__scroll-down
    position: relative
    width: 100%

  &__fab
    visibility: hidden
    position: absolute
    bottom: 32px
    right: 32px
    display: flex
    justify-content: center
    align-items: center
    width: 54px
    height: 54px
    background: $white
    border: .5px solid $gray-200
    border-radius: 50%
    opacity: 0
    transition: visibility $tr-options, background $tr-options, border-color $tr-options, opacity $tr-options
    cursor: pointer

    #{$dark-theme}
      background: $gray-700

    &:hover
      @media (hover)
        #{$light-theme}
          background: $gray-150

        #{$dark-theme}
          border-color: $green

        .icon
          #{$dark-theme}
            fill: $green

    @media #{$tablet}
      bottom: calc(100% + 16px)
      right: 16px

    &_active
      visibility: visible
      opacity: 1

  &__reply
    display: flex
    justify-content: space-between
    align-items: stretch
    gap: 12px
    padding: 16px 20px 0

    @media #{$tablet}
      padding: 16px 8px 0

    .vertical
      height: 100%

  &__wrapper
    display: flex
    justify-content: flex-end
    align-items: flex-end
    gap: 12px
    padding: 16px 20px

    @media #{$tablet}
      padding: 16px 8px

  &__input
    flex-grow: 1
    max-height: 160px
    margin-bottom: 6px
    background: none
    font-size: 16px
    line-height: 20px
    resize: none

    #{$dark-theme}
      color: $white

    &::placeholder
      color: $text-secondary
