
.table
  width: 100%
  border-collapse: collapse

  &__head
    background: $gray-200

    #{$dark-theme}
      background: $gray-600

  &__row
    height: 49px

    .table__body &
      border-bottom: 1px solid $gray-200

      #{$dark-theme}
        border-color: $gray-600

    @mixin active
      background: #f8f8f8

      #{$dark-theme}
        background: $gray-650

    &:has(.table__data_button)
      transition: background $tr-options

      &:hover
        @media (hover)
          @include active

    &_active
      @include active

  &__data
    padding: 8px
    font-size: 16px
    line-height: 20px

    &:first-of-type
      padding-left: 16px

    &:last-of-type
      padding-right: 16px

    &_background
      background: #f8f8f8

      #{$dark-theme}
        background: $gray-650

    &_button
      opacity: 0
      transition: opacity $tr-options

      @media (hover: none)
        opacity: 1

      &:hover
        @media (hover)
          opacity: 1

      .table__row:hover &
        @media (hover)
          opacity: 1

      .table__row_active &
        opacity: 1

    &_icon
      padding: 0
      width: 32px

      &:last-of-type
        padding-right: 8px
