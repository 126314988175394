.profile
  position: absolute
  top: 0
  right: 0
  display: flex
  flex-direction: column
  width: 380px
  max-width: 100%
  height: 100%
  padding: 16px
  background: $white
  border-radius: 16px
  box-shadow: $box-shadow
  opacity: 0
  z-index: 2
  transition: opacity $tr-options

  #{$dark-theme}
    background: $gray-700

  @media #{$tablet}
    border-bottom-right-radius: 0

  @media #{$mobile}
    width: 100%
    border-bottom-left-radius: 0

  &_active
    opacity: 1

  &__closer
    display: flex
    align-self: flex-end
    cursor: pointer

    .icon
      stroke: $gray-500

    &:hover
      @media (hover)
        .icon
          stroke: $primary-light

  &__about
    min-height: 48px
    max-height: 72px
    padding: 12px 16px
    background: #fff9e5
    border-radius: 16px
    color: $text

    #{$dark-theme}
      background: #9b906c
      color: $white

    @at-root textarea#{&}
      font-size: 16px
      line-height: 24px
      transition: opacity $tr-options
      resize: none

      &:hover
        @media (hover)
          opacity: .8

      &:focus
        opacity: 1

      &::placeholder
        color: $light

        #{$dark-theme}
          color: $gray-300

  &__list
    background: $gray-150
    border-radius: 16px

    #{$dark-theme}
      background: $gray-770

    &:empty
      display: none

  &__item
    display: flex
    align-items: center
    min-height: 48px
    padding: 0 16px
    cursor: pointer
    user-select: none

    &:hover
      @media (hover)
        .profile__item-icon
          fill: $primary-light

    &_block
      color: #c9162b

      &:hover
        @media (hover)
          text-decoration: underline

      .text
        #{$any-theme}
          color: #c9162b

    &-content
      display: flex
      justify-content: space-between
      align-items: flex-end
      width: 100%
      gap: 8px
      padding: 8px 0

      .profile__item:not(:last-child) &
        border-bottom: 1px solid $gray-300

        #{$dark-theme}
          border-color: $gray-600

    &-icon
      fill: $text

      #{$dark-theme}
        fill: $white
