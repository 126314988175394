.feature-list
  display: flex
  flex-direction: column
  gap: 12px
  font-size: 14px
  line-height: 16px

  &__item
    display: flex
    gap: 8px
    font-size: 14px
    line-height: 16px

    & > .icon
      margin-top: -2px

    &_inactive
      opacity: .2

    &_tooltip
      position: relative
      text-decoration: underline
      text-decoration-style: dashed

      &:hover
        & > .tooltip
          visibility: visible
          opacity: 1

      & > .tooltip
        top: calc(100% + 8px)
        width: max-content
        max-width: 100%

    &-badge
      display: inline-flex
      padding: 0 6px
      background: #cee6f1
      border-radius: 999px
      color: $blue-tg
      font-size: 12px
      font-weight: 500
      line-height: 16px

      span
        transform: translateY(-0.6px)
