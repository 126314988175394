.wrapper
  display: flex
  flex-wrap: wrap
  max-width: 100%

  &:empty
    display: none

  &_align-items

    &-center
      align-items: center

    &-flex-end
      align-items: flex-end

    &-stretch
      align-items: stretch

  &_align-self

    &-flex-start
      align-self: flex-start

  &_direction
    &-column
      flex-direction: column

    &-row-reverse
      flex-direction: row-reverse

  &_gap
    &-2
      gap: 2px

    &-4
      gap: 4px

    &-6
      gap: 6px

    &-8
      gap: 8px

    &-12
      gap: 12px

    &-16
      gap: 16px

    &-24
      gap: 24px

    &-48
      gap: 48px

  &_resp-wrap
    flex-wrap: nowrap

    @media #{$mobile}
      flex-wrap: wrap
