.header
  grid-column-start: 1
  grid-column-end: 3
  padding: 16px 24px
  background: $gray-100
  border-radius: 16px
  box-shadow: $box-shadow

  #{$dark-theme}
    background: $gray-700

  @media #{$laptop}
    grid-column-end: 3

  @media #{$tablet}
    grid-column-end: 2
    padding: 8px 16px
    border-radius: 0 0 16px 16px

  &__wrapper
    display: flex
    align-items: center
    gap: 24px
    width: 100%

    @media #{$tablet}
      gap: 8px

    .select-min
      &:nth-of-type(3)
        @media #{$mobile}
          margin-left: auto

        .select__dropdown
          @media #{$mobile}
            left: initial
            right: -16px

  &__manager
    @media #{$tablet}
      display: none
