.tag
  position: relative
  flex-shrink: 0
  min-width: 20px
  max-width: 100%
  padding: 2px 6px
  border-radius: 999px

  .icon
    stroke: $text-main

  .text
    #{$any-theme}
      color: $text-main

    &_size-12
      transform: translateY(-0.6px)

    &_size-16
      transform: translateY(-0.8px)

  &:has(.tag__delete)
    padding-right: 24px

  &:has(.text_size-16)
    min-width: 24px

  &_dark
    .icon
      stroke: $white

    .text
      #{$any-theme}
        color: $white

  &__delete
    position: absolute
    top: -2px
    right: -2px

    &:hover
      .icon
        @media (hover)
          opacity: .8
          stroke: $text-main

          .tag_dark &
            stroke: $white
