.widget
  display: grid
  grid-template-columns: minmax(320px, auto) minmax(auto, 375px)
  grid-gap: 12px
  height: 100%

  @media #{$laptop}
    grid-template-columns: 1fr
    grid-template-rows: 1fr 1fr

  &__window
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    background: #d6e0f8
    border-radius: 16px

    #{$dark-theme}
      background: #52607e

  &__controls
    display: flex
    gap: 8px
    padding: 10px

    &-item
      width: 12px
      height: 12px
      background: rgb(256, 256, 256, .7)
      border-radius: 50%

  &__browser
    display: flex
    flex-direction: column
    flex-grow: 1
    background: $white
    border-radius: 16px
    overflow: hidden

    #{$dark-theme}
      background: #303443

  &__bar
    padding: 8px 72px
    border-radius: 16px 16px 0 0

    #{$dark-theme}
      background: #33405c

  &__address-bar
    width: 100%
    padding: 4px 12px
    background: #d9d9d9
    border-radius: 999px
    outline: 2px solid transparent
    font-size: 12px
    line-height: 16px
    transition: outline-color $tr-options, opacity $tr-options
    user-select: none

    #{$dark-theme}
      background: #989898
      color: $white

    &::placeholder
      color: $text-secondary

      #{$dark-theme}
        color: $gray-300

    &:hover
      @media (hover)
        opacity: .8

    &:focus
      opacity: 1
      outline-color: $blue-tg

  &__page
    position: relative
    flex-grow: 1
    max-width: 100%

  &__iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    &_bg
      background: $white
