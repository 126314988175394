.input-color
  position: relative
  width: 100%
  max-width: 360px

  &__button
    position: absolute
    top: 4px
    left: 4px
    width: 40px
    height: 40px
    border: 1px solid $gray-250
    border-radius: 4px
    transition: opacity $tr-options
    cursor: pointer

    #{$dark-theme}
      border-color: $gray-600

    &:hover
      @media (hover)
        opacity: .8

  &__field
    width: 100%
    height: 48px
    padding: 12px 12px 12px 52px
    border: 1px solid $gray-250
    border-radius: 8px
    color: $text-main
    font-size: 16px
    font-weight: 500
    line-height: 24px

    #{$dark-theme}
      border-color: $gray-600
      color: $white

    &::placeholder
      color: $light

      #{$dark-theme}
        color: $gray-500
