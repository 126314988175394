.workspace
  display: grid
  grid-template-columns: minmax(320px, 1fr) minmax(0, 3fr)
  grid-template-rows: auto minmax(0, 1fr)
  grid-gap: 12px
  max-width: 1680px
  height: 100svh
  margin: 0 auto
  padding: 16px 24px

  @media #{$tablet}
    grid-template-columns: minmax(0, 1fr)
    padding: 0
