.aside
  display: flex
  flex-direction: column
  min-width: 0
  min-height: 0
  background: $white
  border-radius: 16px
  box-shadow: $box-shadow

  #{$dark-theme}
    background: $gray-700

  @media #{$laptop}
    order: -1

  &__header
    padding: 16px 16px 0

  &__body
    flex-grow: 1
    padding: 16px 16px 32px

  &__footer
    margin: 0 16px
    padding: 16px 0
    border-top: 2px solid $gray-200

    #{$dark-theme}
      border-color: $gray-600
