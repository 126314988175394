.dialog
  padding: 8px 12px
  transition: background $tr-options
  user-select: none

  &:hover
    @media (hover)
      background: $gray-250

      #{$dark-theme}
        background: $gray-770

  &:not(:last-of-type), &:first-of-type
    border-bottom: 1px solid $gray-300

    #{$dark-theme}
      border-color: $gray-600

  &:not(&_active)
    cursor: pointer

  &_active
    background: $gray-250
    cursor: default

    #{$dark-theme}
      background: $gray-770

  &__manager
    display: flex
    align-items: flex-end
    gap: 4px
    margin-top: -4px

  &__wrapper
    width: 100%
    min-width: 0

  &__top
    display: flex
    justify-content: space-between
    align-items: flex-end
    gap: 6px
    height: 20px

  &__metadata
    display: flex
    align-items: center
    height: 32px

  &__datetime
    color: $gray-850
    text-transform: capitalize

  &__bottom
    display: flex
    justify-content: space-between
    gap: 6px

  &__message
    #{$dark-theme}
      color: $gray-400 !important

  &__badges
    display: flex
    align-items: center
    height: 20px

  &__count
    flex-shrink: 0
    min-width: 20px
    height: 20px
    padding: 0 4px
    background: $blue-tg
    border: 2px solid $white
    border-radius: 72px

    &_muted
      background: $gray-400

  &__labels
    margin: 4px 0 -4px
