.input-alt
  position: relative
  width: 100%
  max-width: 360px

  & > .icon
    position: absolute
    top: 4px
    left: 4px
    pointer-events: none

  &__field
    width: 100%
    padding: 8px 12px
    background: $gray-150
    border-radius: 8px
    font-size: 16px
    line-height: 24px
    outline: 1px solid transparent
    transition: outline $tr-options

    #{$dark-theme}
      background: $gray-650
      color: $white

    &::placeholder
      color: $light

    &[type='search']
      padding: 8px 40px

      &::-webkit-search-cancel-button
        display: none

    .input-alt_clear &
      padding-right: 40px

    .input-alt_error &
      outline: 1px solid $error

    .input-alt_light &
      background: $gray-100

      #{$dark-theme}
        background: $gray-600

  &__button
    position: absolute
    top: 4px
    right: 4px
    visibility: hidden
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options

    &_active
      visibility: visible
      opacity: 1
