.auth
  background: linear-gradient(90deg, transparent 0, $white 50%)

  &__wrapper
    position: relative
    display: grid
    grid-template-columns: 1fr 2fr
    max-width: 1680px
    min-height: 100svh
    margin: 0 auto

    @media #{$tablet}
      grid-template-columns: 1fr
      grid-template-rows: auto minmax(0, 1fr)

  &__side, &__main
    padding: 56px 32px

    @media #{$tablet}
      padding: 0 16px

  &__side
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    & > .logo
      @media #{$tablet}
        order: 1

      @media #{$mobile}
        display: block

    #{$dark-theme}
      background: #365964

    @media #{$tablet}
      flex-direction: row
      justify-content: space-between
      gap: 8px

    & > .select-min
      position: absolute
      top: 56px
      right: 32px

      @media #{$tablet}
        position: relative
        top: initial
        right: initial
        margin-right: auto

  &__main
    display: flex
    justify-content: center
    align-items: center
    background: $white

    #{$dark-theme}
      background: #1f2b39

  &__web
    width: 100%
    max-width: 474px
    aspect-ratio: 237 / 242
    background: url('#{$path}/img/auth/web.png') no-repeat center / contain

    #{$dark-theme}
      background-image: url('#{$path}/img/auth/web_alt.png')

    @media #{$tablet}
      width: initial
      height: 60px

  &__block
    display: flex
    flex-direction: column
    align-items: stretch
    width: 100%
    max-width: 360px

    .text
      color: $green

    .title
      text-align: center
