.icon
  flex-shrink: 0
  fill: $gray-850
  transition: fill $tr-options, stroke $tr-options, opacity $tr-options

  #{$dark-theme}
    fill: $gray-350

  &_behavior

    &-blink
      animation: blink 1s infinite alternate linear

      @keyframes blink
        0%
          opacity: 1

        100%
          opacity: 0

    &-rotate
      &-clockwise
        animation: rotate 1s linear infinite

      @keyframes rotate
        0%
          transform: rotate(0deg)

        100%
          transform: rotate(360deg)

  &_color
    &-black
      fill: $black

      #{$dark-theme}
        fill: $white

    &-blue
      #{$any-theme}
        fill: $blue-tg

    &-gray
      #{$any-theme}
        fill: $gray-400

    &-green
      #{$any-theme}
        fill: $green

    &-light
      #{$any-theme}
        fill: $light

    &-none
      #{$any-theme}
        fill: none

    &-red
      #{$any-theme}
        fill: $error

    &-white
      #{$any-theme}
        fill: $white

  &_compacted
    margin: -4px

  &_stroke
    stroke: $gray-850

    #{$any-theme}
      fill: none

    #{$dark-theme}
      stroke: $gray-350

    &.icon_color
      &-blue
        stroke: $blue-tg

      &-green
        stroke: $green

      &-white
        stroke: $white
