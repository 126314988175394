.checkbox
  display: flex
  flex-wrap: nowrap
  gap: 8px
  width: fit-content
  max-width: 360px
  cursor: pointer
  user-select: none

  input[type='checkbox']
    &:checked
      & ~ .checkbox__button
        background: $green

        &::after
          opacity: 1

  &__button
    flex-shrink: 0
    position: relative
    width: 24px
    height: 24px
    border-radius: 6px
    background: #cecece
    transition: background $tr-options, opacity $tr-options

    &::after
      content: ''
      position: absolute
      top: 2px
      left: 2px
      width: 20px
      height: 20px
      background: url('#{$path}/img/icons/check.svg') no-repeat center / contain
      opacity: 0
      transition: opacity $tr-options

    .checkbox:hover &
      @media (hover)
        opacity: .8
