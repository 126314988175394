.dropzone-alt
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  max-width: 360px
  min-height: 40px
  padding: 0 8px
  border: 1px dashed $blue-tg
  border-radius: 6px
  transition: opacity $tr-options
  cursor: pointer

  &:hover
    @media (hover)
      opacity: .8

  &_active
    opacity: .8
