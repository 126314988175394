.emoji-picker
  position: relative
  display: none

  @media (hover)
    display: initial

  &:hover
    .emoji-picker__mart
      visibility: visible
      opacity: 1

    .icon
      fill: $primary-light

  &__mart
    visibility: hidden
    position: absolute
    bottom: calc(100% + 6px)
    right: 0
    opacity: 0
    z-index: 1
    transition: visibility $tr-options, opacity $tr-options
