.placeholder
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .app > &
    height: 100svh

  &_delay
    opacity: 0
    animation: show 0s .15s forwards

  &_max-height
    height: 100%

  &__img
    width: 100%
    height: 192px
    background: url('#{$path}/img/empty-folder.png') no-repeat center / contain

    &_chat
      background-image: url('#{$path}/img/chat.png')
