.backdrop
  visibility: hidden
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  transition: visibility $tr-options, opacity $tr-options
  z-index: 3

  &_active
    visibility: visible
    opacity: 1

  &__content
    position: absolute
    padding-bottom: 16px
